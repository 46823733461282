function submitOnIDP(event) {
    event.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    let elementWrapper = document.getElementById('password-wrapper');
    let errorElement = document.getElementById('password-error-msg');

    elementWrapper.classList.remove('has-error');
    errorElement.classList.add('hidden');
    disableSubmitButtons();

    firebase.auth().signInWithEmailAndPassword(email, password).then(function () {
        return firebase.auth().currentUser.getIdToken(true);
    }).then(function (token) {
        // Set localstorage token
        localStorage.setItem('idp_token', token);
        // fetch api auth login
        fetch('/api/auth/login', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'client_id': 1,
                'login_profile': 1,
                'login_method': 1
            }),
            method: 'POST'
        }).then(function (data) {
            if (!data.ok) {
                // Returning promise response, should be an object once it's done
                return data.json();
            } else {
                // Display success message
                let successElement = document.getElementById('success-msg');
                successElement.classList.remove('hidden');
                successElement.innerHTML = 'Login successful. Loading Dashboard...';
                window.location.href = '/';
            }
        }).then(function (error) {
            // Using this to get the promise response at the end.
            if (error !== undefined) {
                throw Error(error.message);
            }
        }).catch(function (error) {
            enableSubmitButtons();
            elementWrapper.classList.add('has-error');
            errorElement.classList.remove('hidden');
            errorElement.innerHTML = `<strong> ${error} </strong>`;
            return false;
        });
    }).catch(function (error, other) {
        enableSubmitButtons();
        elementWrapper.classList.add('has-error');
        errorElement.classList.remove('hidden');
        errorElement.innerHTML = `<strong> Invalid Credentials. </strong>`;
        return false;
    });
}

let form = document.getElementById('app-login-form');
if (form) {
    form.addEventListener('submit', submitOnIDP);
}

function onSubmitRegistration(event)
{
    disableSubmitButtons();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password_confirmation').value;

    let errorElement = document.getElementById('error-msg');

    errorElement.classList.add('hidden');

    let error = '';

    if (password != passwordConfirm) {
        error = 'Password and password confirmation should match.';
    } else if (password.length < 6) {
        error = 'Password should be at least 6 characters lenght';
    }

    if (error.length) {
        enableSubmitButtons();
        event.preventDefault();
        errorElement.classList.remove('hidden');
        errorElement.innerHTML = `<ul><li> ${error} </strong>`;
        return false;
    }

    event.returnValue = true;

    return true;
}

function disableSubmitButtons()
{
    return toggleSubmitButtonsDisabled(true);
}
function enableSubmitButtons()
{
    return toggleSubmitButtonsDisabled(false);
}
function toggleSubmitButtonsDisabled(disabled = false)
{
    let submits = document.getElementsByTagName('button');
    for (let i = 0; i < submits.length; i++) {
        let submit = submits[i];
        if (submit.type === 'submit') {
            submit.disabled = disabled;
        }
    }
}

let customerRegistrationForm = document.getElementById('customer-registration');
if (customerRegistrationForm) {
    customerRegistrationForm.addEventListener('submit', onSubmitRegistration);
}
